$breakpoint-sm: 768px;
$breakpoint-md: 992px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1440px;

@mixin breakpoint($class) {
    @if $class == xs {
        @media (max-width: #{$breakpoint-sm - 1px}) {
            @content;
        }
    } @else if $class == sm {
        @media (min-width: $breakpoint-sm) {
            @content;
        }
    } @else if $class == md {
        @media (min-width: $breakpoint-md) {
            @content;
        }
    } @else if $class == lg {
        @media (min-width: $breakpoint-lg) {
            @content;
        }
    } @else if $class == xl {
        @media (min-width: $breakpoint-xl) {
            @content;
        }
    } @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg, xl";
    }
}

.mobile-only {
    @include breakpoint(sm) {
        display: none !important;
    }
}

.mobile-hidden {
    @include breakpoint(xs) {
        display: none !important;
    }
}
