import { getLocaleFirstDayOfWeek } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NativeDateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { startWith, tap } from 'rxjs/operators';


@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    public constructor(@Inject(LOCALE_ID) public locale: string, translate: TranslateService) {
        super();
        translate.onLangChange
            .pipe(
                startWith({ lang: translate.currentLang }),
                tap((locale) => this.setLocale(locale.lang)),
                takeUntilDestroyed()
            )
            .subscribe();
    }

    public override getFirstDayOfWeek(): number {
        return getLocaleFirstDayOfWeek(this.locale);
    }
}
