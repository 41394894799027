import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ApiResponseStatus, IComment, TaskApiService } from 'shared';

import { EntityType } from '../../models/entity.model';
import { ISelectedImage } from '../components/entity-components/image-selector/image-selector.component';
import { TaskService } from './task.service';

@Injectable({
    providedIn: 'root',
})
export class CommentService {
    private constructor(private readonly taskApiService: TaskApiService, private taskService: TaskService) {}

    public listComments(entityType: EntityType, entityId: string): Observable<Array<IComment>> {
        return this.taskApiService.listComment(this.parseEntityType(entityType), entityId);
    }

    public createComment(
        entityType: EntityType,
        entityId: string,
        commentText: string,
        commentImages?: Array<ISelectedImage>
    ): Observable<ApiResponseStatus<IComment>> {
        return this.taskApiService
            .postComment(
                this.parseEntityType(entityType),
                entityId,
                commentText,
                commentImages?.map((image) => image.file)
            )
            .pipe(
                mergeMap(async (res) => {
                    if (res.status === 'completed') {
                        await this.taskService.fetchTask(entityId);
                    }
                    return res;
                })
            );
    }

    public getComment(commentId: string): Observable<IComment> {
        return this.taskApiService.getComment(commentId);
    }

    private parseEntityType(entityType: EntityType): string {
        return `${entityType.charAt(0).toUpperCase()}${entityType.slice(1).toLowerCase()}`;
    }
}
