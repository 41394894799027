@use 'sass:color';
@use 'materials';
@forward 'variables';
@use 'variables';
@use 'responsive';
@use 'shadows';

.modal {
    @include materials.matPlainCard();
    @include shadows.material-shadow(2);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    pointer-events: all;

    //sm - default
    max-width: 380px;

    //md
    &.modal-md {
        max-width: 512px;
    }
    //md
    &.modal-lg {
        max-width: 768px;
    }

    &.modal-xl {
        max-width: 1024px;
    }

    .modal-header {
        margin: 0 40px;
        height: 45px;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:first-child {
            margin-top: 24px;
        }

        &:last-child {
            margin-bottom: 24px;
        }

        .modal-title {
            flex: 1;
            font-size: 24px;
            font-weight: 600;
        }

        .modal-close {
            $background-color: variables.$color-gray-2;
            $color: variables.$color-gray-5;
            margin-right: -16px;
            flex-shrink: 0;
            height: 48px;
            width: 48px;
            border-radius: 99999999px;
            background-color: $background-color;
            color: $color;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: all 0.2s ease;
            outline: none;
            border: none;

            &:hover {
                background-color: color.adjust($background-color, $lightness: -8%);
            }

            &:active {
                background-color: color.adjust($background-color, $lightness: -16%);
            }
        }
    }

    .modal-btn-row,
    .modal-content {
        margin: 0 40px;

        &:last-child {
            margin-bottom: 40px;
        }

        &:first-child {
            margin-top: 40px;
        }

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    .modal-btn-row {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: stretch;

        button {
            flex: 1;
            margin: 0;
            &:not(:first-child) {
                margin-left: 10px;
            }
        }
    }
}
