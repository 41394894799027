import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { hshrink, vshrink } from 'shared';

import { EntityAction } from '../../../../models/entity-action.model';
import { DomComponent, DomService } from '../../../services/dom.service';
import { SocketService } from '../../../services/socket.service';
import { DetailPaneActionsMorePopupComponent } from './detail-pane-actions-more-popup/detail-pane-actions-more-popup.component';

@Component({
    selector: 'app-detail-pane-actions',
    templateUrl: './detail-pane-actions.component.html',
    styleUrls: ['./detail-pane-actions.component.scss'],
    animations: [vshrink(), hshrink()],
})
export class DetailPaneActionsComponent implements OnInit, OnDestroy {
    @Input() public actions: Array<EntityAction>;

    private morePopup: DomComponent<DetailPaneActionsMorePopupComponent>;

    public constructor(private domService: DomService, public socketService: SocketService) {}

    public ngOnInit(): void {
        if (!this.actions) this.actions = [];
    }

    public ngOnDestroy(): void {
        if (this.morePopup) {
            this.morePopup.remove();
            this.morePopup = null;
        }
    }

    protected get primaryAction(): EntityAction {
        return this.actions?.length >= 1 ? this.actions[0] : null;
    }

    protected get secondaryAction(): EntityAction {
        return this.actions?.length === 2 ? this.actions[1] : null;
    }

    protected get moreActions(): Array<EntityAction> {
        return this.actions?.length > 2 ? this.actions.slice(1) : [];
    }

    protected clickSecondary($event: MouseEvent): void {
        if (this.secondaryAction) {
            this.secondaryAction.onClick($event);
        } else if (this.moreActions.length) {
            // Remove existing popup
            if (this.morePopup) {
                this.morePopup.remove();
                this.morePopup = null;
            }
            // Create "more" popup
            this.morePopup = this.domService.appendComponentTo<DetailPaneActionsMorePopupComponent>(
                'overlay-container',
                DetailPaneActionsMorePopupComponent,
                {
                    actions: this.moreActions,
                    origin: [$event.clientX, $event.clientY],
                }
            );
        }
    }
}
