@use 'popups';
@use 'materials';
@use 'inputs';
@use 'modals';
@use 'buttons';
@use 'variables';
@use 'responsive';

app-confirm-info-popup {
    width: 100%;
    height: 100%;
    @include materials.matBlurDark();
    border-radius: 0;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: all;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    border-top: variables.$color-gray-2 solid 1px;
    border-bottom: variables.$color-gray-2 solid 1px;
    padding: 20px 0;
    @include responsive.breakpoint(xs) {
        flex-direction: column-reverse;
    }
}

.prompt {
    width: 100%;

    input {
        margin-top: 8px;
        width: 100%;
        border: 1px solid variables.$color-gray-2;
    }
}

.message {
    max-width: 100%;
    word-break: break-word;
    white-space: pre-line;

    span {
        opacity: 80%;
        font-size: 13px;
        color: red;
    }
}
