//
// BASE COLOR PALETTE
//

$color-gray-1: #f6f6f6;
$color-gray-2: #ebebeb;
$color-gray-3: #b6b6b6;
$color-gray-4: #646464;
$color-gray-5: #373737;

$color-gray-text-1: #555;

$color-red: #ed6d49;
$color-orange: #ffc238;
$color-green: #5dd784;
$color-blue: #27a6ff;

$color-severe: $color-red;
$color-warning: $color-orange;
$color-info: $color-blue;
$color-success: $color-green;

$color-selection-blue: #e7f5ff;
$color-selection-orange: #fff1e7;
$color-selection-red: #ffe7e7;

$table-margin-history: 380px;
$detail-pane-margin-history: 159px;
