.popup-arrow {
    border-color: white transparent;
    border-style: solid;
    border-width: 0px 10px 10px 10px;
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-10px);
    z-index: 10;
}
