import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITask } from 'shared';

import { SharingService } from '../sharing.service';
import { SocketService } from '../socket.service';
import { TaskService } from '../task.service';
import { BaseSocketEventHandler } from './base-socket-event-handler';

interface TaskStatusUpdatedSocketEvent {
    eventDtoType: 'TaskStatusUpdated';
    task: ITask;
}

@Injectable({ providedIn: 'root' })
export class TaskStatusUpdatedSocketEventHandler extends BaseSocketEventHandler<TaskStatusUpdatedSocketEvent> {
    protected constructor(
        private taskService: TaskService,
        socketService: SocketService,
        private sharingService: SharingService
    ) {
        super(socketService);
    }

    protected eventDtoType(): string {
        return 'TaskStatusUpdated';
    }

    protected async handleEvent(event: TaskStatusUpdatedSocketEvent): Promise<void> {
        this.taskService.localUpdate(event.task);
        // Get shared shared task
        const sharedEntity = await firstValueFrom(this.sharingService.sharedEntity);
        // Update shared issue
        if (sharedEntity && sharedEntity.type === 'SHARED_TASK' && sharedEntity.task.id === event.task.id) {
            sharedEntity.task.status = event.task.status;
            this.sharingService.updateSharedEntity(sharedEntity);
        }
    }
}
