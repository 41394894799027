@import 'material-icons/iconfont/material-icons.css';

.collapsible {
    &-header {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &-title {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 1.1em;
    }

    .icon {
        margin-right: 4px;
    }

    .control {
        margin-left: auto;
        float: right;
        user-select: none;
        transition: transform 0.25s ease;

        &.collapsed {
            transform: rotate(180deg);
        }
    }

    &-content {
        color: var(--collapsible-content-color, inherit);

        strong {
            color: var(--collapsible-content-strong-color, inherit);
            font-weight: var(--collapsible-content-strong-weight, initial);
        }
    }
}
