@use 'popups';
@use 'materials';
@use 'inputs';
@use 'modals';
@use 'buttons';
@use 'variables';
@use 'responsive';

app-edit-filter-set-popup {
    width: 100%;
    height: 100%;
    @include materials.matBlurDark();
    border-radius: 0;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: all;
}

.message {
    max-width: 100%;
    word-break: break-word;
    margin: 10px 20px;
    quotes: auto;

    q {
        font-size: 15px;
        font-weight: 650;
    }

    span {
        opacity: 80%;
        font-size: 13px;
        color: red;
        margin: -20px;
    }
}

h3 {
    margin-left: 20px;
}

.filter-changes-container {
    margin-top: 20px;
}

.changes-list {
    display: flex;
    flex-direction: column;
    margin: 0 10px 0 -40px;
}

.changed-item-list-item {
    display: flex;
    align-items: flex-start;
    font-size: 17px;
    padding-bottom: 10px;

    i {
        scale: 1.5;
        border-radius: 50%;
        margin: 2px 16px;
        position: relative;
        top: 2px;
    }

    .icon-added > * {
        color: variables.$color-green;
        background-color: rgba(variables.$color-green, 0.05);
    }

    .icon-removed > * {
        color: variables.$color-red;
        background-color: rgba(variables.$color-red, 0.05);
    }

    .filter-type-item {
        opacity: 0.7;
        font-weight: 400;
        font-size: 14px;
        font-style: italic;
    }

    .more-toggle {
        opacity: 1;
        color: var(--color-brand-secondary);
        font-weight: 600;
        font-style: normal;
    }

    .more-toggle:hover {
        cursor: pointer;
    }
}

.row-show {
    margin: 2px 0 0 -10px;
}

.filter-type-title {
    font-size: 17px;
    font-style: normal;
    opacity: 1;
    font-weight: 650;
}

.extra-filters-container {
    float: left;
    padding-right: 10px;
}
