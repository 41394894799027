import { Observable, ThrottleConfig, asyncScheduler, fromEvent, merge } from 'rxjs';
import { debounceTime, filter, map, startWith, throttleTime } from 'rxjs/operators';

const viewportChangeEvents = [
    'drag',
    'resize',
    'wheel',
    'touchmove',
    'move',
    'zoom',
    'rotate',
    'pitch',
    'drag',
    'load',
];

export class MapHelper {
    public static onViewPortChangeThrottled(
        mapboxMap: mapboxgl.Map,
        throttleDuration = 500,
        throttleConfig: ThrottleConfig = {
            leading: false,
            trailing: true,
        }
    ): Observable<GeoJSON.Polygon> {
        return merge(...viewportChangeEvents.map((event) => fromEvent(mapboxMap, event))).pipe(
            startWith(null),
            throttleTime(throttleDuration, asyncScheduler, throttleConfig),
            map(() => this.getViewBoundsAsGeoJSON(mapboxMap)),
            filter(Boolean)
        );
    }

    public static onViewPortChangeDebounced(
        mapboxMap: mapboxgl.Map,
        debounceDuration = 500,
        kickstart = true
    ): Observable<GeoJSON.Polygon> {
        let events = merge(...viewportChangeEvents.map((event) => fromEvent(mapboxMap, event))).pipe(
            debounceTime(debounceDuration)
        );

        if (kickstart) events = events.pipe(startWith(null));

        return events.pipe(
            map(() => this.getViewBoundsAsGeoJSON(mapboxMap)),
            filter(Boolean)
        );
    }

    public static getViewBoundsAsGeoJSON(map?: mapboxgl.Map): GeoJSON.Polygon | null {
        if (!map) return null;
        const canvas = map.getCanvas();
        const width = canvas.clientWidth;
        const height = canvas.clientHeight;
        const cUL = map.unproject([0, 0]).toArray();
        const cUR = map.unproject([width, 0]).toArray();
        const cLR = map.unproject([width, height]).toArray();
        const cLL = map.unproject([0, height]).toArray();
        return {
            type: 'Polygon',
            coordinates: [[cUL, cUR, cLR, cLL, cUL]],
        };
    }
}
