<div [class]="'modal modal-md'">
    <div class="modal-header">
        <div class="modal-title">
            {{ options.title || 'comp.confirm-info-popup.title-' + options.action | translate }}
        </div>
        <button class="modal-close" (click)="close(false, $event)">
            <i class="material-icons">clear</i>
        </button>
    </div>

    <div class="modal-content">
        <div class="message" [innerHTML]="options.message | translate"></div>
        <div class="prompt" *ngIf="options.action === 'prompt'">
            <div class="message" *ngIf="validationErrors?.includes('MIN_LENGTH_ERROR')">
                <span>
                    {{
                        options.config?.minLengthErrorKey ?? 'comp.confirm-info-popup.saveFilterSetPopup.errors.default'
                            | translate : { minLength: options.config?.minLength ?? 1 }
                    }}
                </span>
            </div>
            <div class="message" *ngIf="validationErrors?.includes('MAX_LENGTH_ERROR')">
                <span>
                    {{
                        options.config?.maxLengthErrorKey ?? 'comp.confirm-info-popup.saveFilterSetPopup.errors.default'
                            | translate : { maxLength: options.config?.maxLength ?? 1 }
                    }}
                </span>
            </div>
            <input
                #promptInput
                class="input"
                type="text"
                [(ngModel)]="prompt"
                (keydown.enter)="onInputChange(true)"
                (input)="onInputChange()"
                [placeholder]="options.config?.placeholder ? (options.config?.placeholder | translate) : ''"
            />
        </div>
    </div>
    <div class="modal-btn-row">
        <button
            class="btn btn-small"
            (click)="close(true)"
            *ngIf="options.action !== 'info'"
            [disabled]="validationErrors?.length !== 0 || prompt?.length === 0"
            [class]="options.action === 'delete' ? 'color-red' : 'color-secondary'"
        >
            {{ getConfirmLabel() | translate }}
        </button>

        <button class="btn btn color-primary btn-outline btn-small" (click)="close(false, $event)">
            {{ getCancelLabel() | translate }}
        </button>
    </div>
</div>
