@use 'variables';

.no-logs {
    color: variables.$color-gray-5;
    text-align: center;
    width: 100%;
}

app-audit-log-entry {
    padding: 12px 0;

    &:first-child {
        margin-top: 12px;
    }
}
