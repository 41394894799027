@use 'shadows';
@forward 'variables';
@use 'variables';

$mat-blur-dark-inner-border-color: rgba(255, 255, 255, 0.3);
$mat-blur-dark-outer-border-color: rgba(0, 0, 0, 0.7);

@mixin matBlur() {
    box-shadow: shadows.$shadow-1, inset 0px 0px 0px 1px $mat-blur-dark-inner-border-color,
        0px 0px 0px 1px $mat-blur-dark-outer-border-color;
    backdrop-filter: blur(10px);
    background: transparent;
    border-radius: 8px;
    color: white;
}

@mixin matBlurLight() {
    box-shadow: shadows.$shadow-1, inset 0px 0px 0px 1px $mat-blur-dark-inner-border-color,
        0px 0px 0px 1px $mat-blur-dark-outer-border-color;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    color: variables.$color-gray-5;
}

@mixin matBlurDark() {
    box-shadow: shadows.$shadow-1, inset 0px 0px 0px 1px $mat-blur-dark-inner-border-color,
        0px 0px 0px 1px $mat-blur-dark-outer-border-color;
    backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    color: white;
}

@mixin matPlainCard() {
    color: variables.$color-gray-5;
    box-shadow: shadows.$shadow-1;
    background: white;
    border: 1px solid variables.$color-gray-2;
    border-radius: 3px;
}
