import {
    ChangeDetectorRef,
    Component,
    DestroyRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    inject,
} from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { StorageMap } from '@ngx-pwa/local-storage';
import { BehaviorSubject, Observable, firstValueFrom, of } from 'rxjs';
import { catchError, scan, tap } from 'rxjs/operators';
import { EntityFilters, ISortingModel, Pagination } from 'shared';

import { hashCode } from '../../../../utils/string-utils';
import { DomComponent, DomService } from '../../../services/dom.service';
import { SimpleSelectPopupComponent } from '../../modals/simple-select-popup/simple-select-popup.component';
import { ITableComponentColumn } from './interfaces/table-component-column.interface';
import { ITableData } from './interfaces/table-data.interface';
import { ITableRow } from './interfaces/table-row.interface';

export const DEFAULT_STEP = 25;
export const STEP_OPTIONS = [25, 50, 100, 250];
export const PAGINATION_STEP_KEY = 'TABLE_PAGINATION_STEP';

@Component({ template: '' })
export abstract class TableBaseComponent<T extends ITableData> implements OnInit, OnChanges {
    protected destroyRef = inject(DestroyRef);

    protected data$: BehaviorSubject<Array<ITableRow<T>>> = new BehaviorSubject<Array<ITableRow<T>>>([]);

    protected pagination$: BehaviorSubject<Pagination> = new BehaviorSubject<Pagination>({
        start: 0,
        step: DEFAULT_STEP,
    });

    protected sorting$: BehaviorSubject<Array<ISortingModel<T>>> = new BehaviorSubject<Array<ISortingModel<T>>>([]);
    protected filter$: BehaviorSubject<EntityFilters> = new BehaviorSubject<EntityFilters>({});
    protected filterPreset: string;
    protected math = Math;
    protected filterCount = 0;
    protected pagination: Pagination;
    protected filterOptions: EntityFilters = {};
    protected tableData: Array<ITableRow<T>> = [];
    protected enabledHeading: Array<ITableComponentColumn<T>>;

    private filterHash = 0;

    public _heading: Array<ITableComponentColumn<T>>;

    public get heading(): Array<ITableComponentColumn<T>> {
        return this._heading;
    }

    @Input()
    public set heading(value: Array<ITableComponentColumn<T>>) {
        this._heading = value;
        this.enabledHeading = value?.filter((h) => h.enabled !== false);
    }

    @Input()
    public data: Array<ITableRow<T>>;

    @Input()
    public selectable = true;

    @Input()
    public highlightRow: string | null;

    @Output()
    public rowClick: EventEmitter<ITableRow<T>> = new EventEmitter();

    @Input()
    public selectedIds: Array<string>;

    @Output()
    public selectedIdsChange: EventEmitter<Array<string>> = new EventEmitter();

    @Input()
    public maxFilters = 0;

    private popupComponent: DomComponent<SimpleSelectPopupComponent<number>>;

    protected constructor(
        protected readonly cdr: ChangeDetectorRef,
        protected readonly domService: DomService,
        protected readonly storage: StorageMap
    ) {}

    public onSelectFilterChange(values: Array<string>, filterName: string): void {
        this.onFilterChange({ ...this.filter$.value, [filterName]: { type: 'SELECT', values } });
    }

    public onDateRangeFilterChange(range: DateRange<Date>, filterName: string): void {
        const filters: EntityFilters = { ...this.filter$.value, [filterName]: { type: 'DATE_RANGE', range } };
        if (range.start === null && range.end === null) {
            delete filters[filterName];
        }
        this.onFilterChange(filters);
    }

    public setFilterPreset(filterPresetId: string): void {
        this.filterPreset = filterPresetId;
    }

    public abstract ngOnInit(): void;

    public abstract ngOnChanges(changes: SimpleChanges): void;

    protected next(): void {
        this.pagination$.next({
            start: Math.min(this.pagination.start + this.pagination.step, this.pagination.total),
            step: this.pagination.step,
        });
    }

    protected hasNext(): boolean {
        return this.pagination.start + this.pagination.step < this.pagination.total;
    }

    protected previous(): void {
        this.pagination$.next({
            start: Math.max(this.pagination.start - this.pagination.step, 0),
            step: this.pagination.step,
        });
    }

    protected hasPrevious(): boolean {
        return this.pagination.start > 0;
    }

    protected trackBy(index: number, item: ITableRow<T>): string | number {
        return item.value.id ?? index;
    }

    protected onPageSizeClick($event: MouseEvent): void {
        $event.stopImmediatePropagation();
        this.popupComponent?.remove();
        this.popupComponent = this.domService.appendComponentTo<SimpleSelectPopupComponent<number>>(
            'overlay-container',
            SimpleSelectPopupComponent,
            {
                origin: [$event.clientX, $event.clientY + 5],
                values: STEP_OPTIONS.map((option) => ({
                    id: option,
                    label: option.toString(),
                })),
                header: 'comp.table.pagination.choosePageSize',
            }
        );
        this.popupComponent.on('remove', () => (this.popupComponent = null));
        this.popupComponent.component.popupResult.subscribe(async (value: number) => {
            this.popupComponent?.remove();
            if (this.pagination.step === value) {
                return;
            }

            this.storage.set(PAGINATION_STEP_KEY, value).subscribe(() => {
                this.pagination.step = value;
                this.pagination$.next({
                    start: 0,
                    step: this.pagination.step,
                    total: 0,
                });
            });
        });
    }

    protected isFunction<TOtherType, TArgumentValue, TReturnValue>(
        val: TOtherType | ((arg: TArgumentValue) => TReturnValue)
    ): val is (arg: TArgumentValue) => TReturnValue {
        return typeof val === 'function';
    }

    protected toggleSelection(row: ITableRow<T>): void {
        this.rowClick.emit(row);
    }

    protected onSelectPage(): void {
        const pageIds = this.tableData.map((t) => t.value.id);

        if (this.isPageSelected()) {
            this.selectedIdsChange.emit([...this.selectedIds.filter((id) => !pageIds.includes(id))]);
        } else {
            this.selectedIdsChange.emit([
                ...this.selectedIds,
                ...pageIds.filter((id) => !this.selectedIds.includes(id)),
            ]);
        }
    }

    protected isPageSelected(): boolean {
        return this.tableData.map((t) => t.value.id).every((id) => this.selectedIds.includes(id));
    }

    protected onRowCheckChange(id: string, checked: boolean): void {
        const index = this.selectedIds.findIndex((_taskId) => _taskId === id);
        if (checked && index < 0) {
            this.selectedIds.push(id);
            this.selectedIdsChange.emit(this.selectedIds);
        } else if (!checked && index >= 0) {
            this.selectedIds.splice(index, 1);
            this.selectedIdsChange.emit(this.selectedIds);
        }
    }

    protected toggleSortState(header: ITableComponentColumn<T>): void {
        let sorting = this.sorting$.value;
        const currentSortState = sorting.find((s) => s.property === header.name);

        if (!currentSortState) {
            // This column isn't currently being sorted, add it to the sorting.
            sorting.push({
                property: header.name,
                direction: 'ascending',
                order: null,
            });
        } else if (currentSortState.direction === 'ascending') {
            // This column is currently being sorted in ascending order, invert the sort.
            currentSortState.direction = 'descending';
        } else {
            // This column is currently being sorted in descending order, remove it from the sorting.
            sorting = sorting.filter((s) => s.property !== header.name);
        }

        // Reset order properties on the currently active sorts.
        sorting.forEach((s, index) => (s.order = index));

        this.sorting$.next(sorting);
    }

    protected getSortState(header: ITableComponentColumn<T>): undefined | 'ascending' | 'descending' {
        return this.sorting$.value.find((s) => s.property === header.name)?.direction;
    }

    protected fillCellCssClasses(data: Array<ITableRow<T>>): void {
        data.forEach((row) => {
            row.cssClass = Object.entries(this._heading).reduce(
                (acc, [key, heading]) => ({
                    ...acc,
                    [heading.name]: this.getCellCssClass(heading, row),
                }),
                {} as Record<keyof T, string>
            );
        });
    }

    protected getCellCssClass(header: ITableComponentColumn<T>, row: ITableRow<T>): string {
        return `${header.name.toString()} ${
            (this.isFunction(header.valueCssClass) ? header.valueCssClass(row.value) : header.valueCssClass) ?? ''
        }`.trim();
    }

    protected abstract subscribeData(): void;

    protected async initBase(): Promise<void> {
        this.pagination = this.pagination$.getValue();

        await firstValueFrom(
            this.storage.get(PAGINATION_STEP_KEY).pipe(
                catchError(() => of(DEFAULT_STEP)),
                tap((result) => {
                    let step = (result as number) ?? DEFAULT_STEP;
                    const maxStep = Math.max(...STEP_OPTIONS);
                    const minStep = Math.min(...STEP_OPTIONS);
                    step = Math.max(Math.min(step, maxStep), minStep);

                    this.pagination.step = step;
                })
            )
        );

        this.subscribeData();
        this._heading = this._heading ?? [];
        this.selectedIds = [];
    }

    protected onHeadingChanges(changes: SimpleChanges): void {
        if (changes.heading?.currentValue) {
            changes.heading.currentValue.map((header: ITableComponentColumn<T>) => {
                header.label = header.label ?? (header.name as string);
            });
        }
    }

    protected getFilters(): Observable<EntityFilters> {
        return this.filter$.pipe(scan((acc, curr) => ({ ...acc, ...curr })));
    }

    protected resetPagination(): void {
        this.pagination$.next({
            start: 0,
            step: this.pagination.step,
            total: 0,
        });
    }

    protected getFilterCount(filters: EntityFilters): number {
        return Object.values(filters).reduce((acc, e) => {
            switch (e.type) {
                case 'SELECT':
                    acc += e.values.length;
                    break;
                case 'DATE_RANGE':
                    acc++;
                    break;
            }
            return acc;
        }, 0);
    }

    protected resetFiltering(): void {
        const filters = this.filter$.value;

        for (const value of Object.values(filters)) {
            if (value.type === 'SELECT') value.values = [];
            if (value.type === 'DATE_RANGE') value.range = null;
        }

        this.filter$.next(filters);
        this.sorting$.next([]);
        this.filterPreset = null;
    }

    private calculateFilterHash(filters: EntityFilters): number {
        const filterHashes: Array<number> = [];
        Object.entries(filters).forEach(([key, value]) => {
            switch (value.type) {
                case 'SELECT':
                    value.values.forEach((v) => filterHashes.push(hashCode(`${value.type}_${key}=${v}`)));
                    break;
                case 'DATE_RANGE':
                    filterHashes.push(
                        hashCode(`${value.type}_${key}=${value.range.start?.getTime()}${value.range.end?.getTime()}`)
                    );
                    break;
                default:
                    console.error(`calculateFilterHash: missing implementation for filter type`, value);
                    break;
            }
        });
        filterHashes.sort();
        return hashCode(filterHashes.join(''));
    }

    private onFilterChange(filters: EntityFilters): void {
        // Determine new filter count
        const newFilterCount = this.getFilterCount(filters);
        if (this.maxFilters > 0 && newFilterCount > this.filterCount && newFilterCount > this.maxFilters) return;

        // Only update filters if they have effective changes
        const filterHash = this.calculateFilterHash(filters);
        if (filterHash === this.filterHash) return;
        this.filterHash = filterHash;

        // Set new filters and reset pagination
        this.filter$.next(filters);
        this.filterCount = newFilterCount;
        this.resetPagination();
        this.cdr.markForCheck();
    }
}
