import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ITask } from 'shared';

import { ISocketEvent } from '../../../models/socket-event.model';
import { SocketService } from '../socket.service';
import { AppStateService } from '../state/app-state.service';
import { TaskService } from '../task.service';
import { UserService } from '../user.service';
import { BaseSocketEventHandler } from './base-socket-event-handler';

@Injectable({ providedIn: 'root' })
export abstract class TaskAssignmentBaseSocketEventHandler<T extends ISocketEvent> extends BaseSocketEventHandler<T> {
    protected constructor(
        private readonly taskService: TaskService,
        private readonly userService: UserService,
        protected readonly appState: AppStateService,
        socketService: SocketService
    ) {
        super(socketService);
    }

    protected async ensureLocalState(event: T): Promise<boolean> {
        const hasPermission = await firstValueFrom(this.canSeeTask(event.task));
        if (hasPermission) this.taskService.localUpdate(event.task);
        else this.taskService.localRemove(event.task.id);
        return hasPermission;
    }

    protected canSeeTask(task: ITask): Observable<boolean> {
        return task.assigneeId
            ? this.appState.user.stream.pipe(
                  switchMap((user) =>
                      task.assigneeId === user.id
                          ? this.userService.hasPermissions(['Tasks.ReadTasks.All', 'Tasks.ReadTasks.Self'], false)
                          : this.userService.hasPermission('Tasks.ReadTasks.All')
                  )
              )
            : this.userService.hasPermissions(['Tasks.ReadTasks.All', 'Tasks.ReadTasks.Unassigned'], false);
    }
}
