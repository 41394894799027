import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, interval, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap, timeout } from 'rxjs/operators';
import { ITask, TaskApiService } from 'shared';

import { AppStateService } from './state/app-state.service';

const MAX_SINT_32 = 2147483647;

@Injectable({ providedIn: 'root' })
export class ActiveTaskService {
    public constructor(private appState: AppStateService, private taskApiService: TaskApiService) {
        // Fetch active tasks
        merge(
            // Every time the socket reconnects
            this.appState.socketStatus.stream.pipe(filter((state) => state.status === 'CONNECTED')),
            // Every minute, if the user is logged in (to prevent getting desynchronized by missed events)
            interval(60000)
        )
            .pipe(
                // Wait for the user to be known
                switchMap(() =>
                    firstValueFrom(
                        this.appState.user.stream.pipe(
                            filter((user) => !!user),
                            timeout(3000)
                        )
                    )
                ),
                switchMap(() => this.fetchActiveTasks()),
                tap((tasks) => (this.appState.activeTasksForUser.value = tasks.map((task) => task.id)))
            )
            .subscribe();
    }

    public onTaskUpdated(task: ITask): void {
        const isActive = task.assigneeId === this.appState.user.value.id && task.status === 'IN_PROGRESS';
        if (isActive && !this.appState.activeTasksForUser.value.includes(task.id)) {
            this.appState.activeTasksForUser.value = [...this.appState.activeTasksForUser.value, task.id];
        } else if (!isActive && this.appState.activeTasksForUser.value.includes(task.id)) {
            this.appState.activeTasksForUser.value = this.appState.activeTasksForUser.value.filter(
                (id) => id !== task.id
            );
        }
    }

    public onTaskRemoved(entityId: string): void {
        this.appState.activeTasksForUser.value = this.appState.activeTasksForUser.value.filter((id) => id !== entityId);
    }

    private fetchActiveTasks(): Observable<Array<ITask>> {
        return this.taskApiService
            .getTasksPaginated(
                { start: 0, step: MAX_SINT_32 },
                {
                    status: {
                        type: 'SELECT',
                        values: ['IN_PROGRESS'],
                    },
                    assigneeId: {
                        type: 'SELECT',
                        values: [this.appState.user.value.id],
                    },
                },
                []
            )
            .pipe(map((resp) => resp.data));
    }
}
