@use 'shadows';
@forward 'variables';
@use 'variables';
@use 'materials';

.btn-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: -5px;

    &.align-start {
        justify-content: flex-start;
    }

    &.align-end {
        justify-content: flex-end;
    }

    button.btn {
        margin-top: 5px;

        &:not(:last-child) {
            margin-right: 5px;
        }
    }
}

@mixin btn-group-vertical() {
    &.btn-group {
        flex-direction: column;

        & > button.btn {
            &:first-child {
                border-radius: 4px 4px 0 0;
            }

            &:last-child {
                border-radius: 0 0 4px 4px;
            }

            &:not(:first-child) {
                border: 1px solid #ebebeb;
                border-top: none;
            }
        }
    }
}

.btn-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    box-shadow: shadows.$shadow-1;
    border-radius: 4px;
    position: relative;

    button.btn {
        box-shadow: none;
        border-radius: 0;
        z-index: 10;

        &:hover {
            transform: scale(1.05);
            z-index: 20;
        }

        &:active {
            transform: scale(1);
            z-index: 20;
        }
    }

    & > button.btn {
        &:first-child {
            border-radius: 4px 0 0 4px;
        }

        &:last-child {
            border-radius: 0 4px 4px 0;
        }

        &:not(:first-child) {
            border: 1px solid #ebebeb;
            border-left: none;
        }
    }
}

button.btn {
    @include materials.matPlainCard();
    min-height: 42px;
    border-radius: 4px;
    transition: all 0.15s ease;
    padding: 12px 18px;
    font-weight: 600;
    font-size: 14px;
    outline: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    position: relative;

    & > .spinner {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 24px;
        height: 24px;
    }

    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    & > i {
        color: variables.$color-blue;
        font-size: 18px;

        &.dark-icon {
            color: rgba(0, 0, 0, 0.5);
        }

        &:not(:last-child) {
            margin-right: 6px;
        }

        &:not(:first-child) {
            margin-left: 6px;
        }

        &.large-icon {
            font-size: 24px;

            &:first-child {
                margin-left: -8px;
                margin-right: 0;
            }

            &:last-child {
                margin-right: -8px;
                margin-left: 0;
            }
        }
    }

    &::-moz-focus-inner {
        border: 0;
    }

    &:not(:disabled) {
        cursor: pointer;

        &:hover {
            &:not(.btn-flat) {
                box-shadow: shadows.$shadow-1;
            }

            transform: translateY(-2px);
        }

        &:active {
            transform: translateY(0);
        }
    }

    &:disabled {
        opacity: 0.5;
    }

    &.color-primary,
    &.color-secondary,
    &.color-red,
    &.color-green,
    &.color-none {
        border: none;

        & > i:not(.dark-icon) {
            color: inherit;
        }
    }

    &.color-primary {
        background-color: var(--color-brand-primary);
        color: var(--color-on-brand-primary);
    }

    &.color-secondary {
        background-color: var(--color-brand-secondary);
        color: var(--color-on-brand-secondary);
    }

    &.color-red {
        background-color: variables.$color-red;
        color: white;
    }

    &.color-green {
        background-color: variables.$color-green;
        color: white;
    }

    &.color-none {
        background-color: transparent;
        color: white;
    }

    &.btn-flat {
        box-shadow: none;
    }

    &.btn-square,
    &.btn-round {
        width: 42px;
        height: 42px;
        padding: 0;

        & > i {
            font-size: 20px;
        }
    }

    &.btn-round {
        border-radius: 99999999999px;
    }

    &.btn-outline {
        background-color: transparent;

        &.color-primary {
            color: var(--color-brand-primary);
            border: 1px solid var(--color-brand-primary);
        }

        &.color-secondary {
            color: var(--color-brand-secondary);
            border: 1px solid var(--color-brand-secondary);
        }

        &.color-red {
            color: variables.$color-red;
            border: 1px solid variables.$color-red;
        }

        &.color-green {
            color: variables.$color-green;
            border: 1px solid variables.$color-green;
        }
    }

    &.btn-no-outline {
        background-color: transparent;
        box-shadow: none;

        &:hover:not(.btn-flat) {
            box-shadow: none;
        }

        &.color-primary {
            color: var(--color-brand-primary);
        }

        &.color-secondary {
            color: var(--color-brand-secondary);
        }

        &.color-red {
            color: variables.$color-red;
        }

        &.color-green {
            color: variables.$color-green;
        }
    }

    &.btn-block {
        width: 100%;
    }

    &.btn-large:not(.btn-normal):not(.btn-small) {
        min-height: 64px;
        padding: 18px 24px;
        font-size: 16px;

        &.btn-round {
            width: 64px;
            height: 64px;
            padding: 0;
        }
    }

    &.btn-small:not(.btn-large):not(.btn-normal) {
        min-height: 35px;
        padding: 5px 12px;

        &:not(.btn-round) {
            border-radius: 3pt;
        }

        &.btn-round,
        &.btn-square {
            width: 35px;
            height: 35px;
            padding: 0;
        }
    }
}
