@use 'variables';
@use 'sass:math';

app-checkbox {
    display: contents;
    background-color: var(--color-brand-primary-dark-5);
    border-color: var(--color-brand-primary-dark-5);
}

.checkbox-container {
    $checkbox-size: 18px;
    display: block;
    position: relative;
    padding-left: $checkbox-size;
    margin: 2px 2px 18px;
    cursor: pointer;
    user-select: none;
    line-height: math.div($checkbox-size, 2);
    font-size: 11px;
    font-weight: bold;
    background-color: inherit;
    border-color: inherit;
    height: 0;
    &.disabled {
        cursor: unset;
    }

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        top: 0;
        left: 0;
        &:disabled {
            cursor: unset;
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: $checkbox-size;
        width: $checkbox-size;
        background-color: white;
        border: 1px solid;
        // shorthand notation gives different results;
        border-color: inherit;
        border-radius: 4px;
        transition: all 0.25s ease;

        &.highlighted {
            border-color: variables.$color-orange;
            border-width: 2px;
        }
    }

    & input:checked ~ .checkmark {
        background-color: inherit;

        &.highlighted {
            border-color: variables.$color-orange;
            border-width: 2px;
            background-color: variables.$color-orange;
        }
    }

    .checkmark:after {
        content: '';
        position: absolute;
        display: block;
        opacity: 0;
        transition: all 0.25s ease;
    }

    & input:checked ~ .checkmark:after {
        opacity: 1;
    }

    & .checkmark:after {
        left: 6px;
        top: 3px;
        width: 2px;
        height: 6px;
        border: solid white;
        border-width: 0 2pt 2pt 0;
        transform: rotate(40deg);
    }

    & input:disabled ~ .checkmark {
        opacity: 0.5;
    }
}
