@forward 'variables';
@use 'variables';
@use 'shadows';
@use 'materials';

.input,
input[type='text'],
input[type='number'],
input[type='password'],
input[type='date'],
input[type='time'],
select,
textarea,
.pseudo-select {
    background-color: #f6f6f6;
    border-radius: 3px;
    padding: 10px;
    border: none;
    font-size: 15px;
    font-weight: 500;
    overflow: hidden;
    resize: none;
    &::placeholder {
        opacity: 0.25;
        font-weight: 600;
    }

    ::placeholder {
        color: variables.$color-gray-3;
    }

    &.small {
        padding: 6px 7px;
        font-size: 12px;
    }
}

select,
.pseudo-select {
    appearance: none;
    padding-right: 40px;
    position: relative;
    font-weight: 500;
    user-select: none;

    .bf-input > & + i:after {
        content: '▼';
        position: absolute;
        right: 14px;
        top: 8px;
        z-index: 1;
        text-align: center;
        height: 100%;
        pointer-events: none;
        color: #d8d8d8;
        font-size: 24px;
    }
}

.bf-input {
    position: relative;
    .material-icons {
        font-size: 16px;
        color: var(--color-brand-secondary);
        position: absolute;
        left: 16px;
        top: 21px;
        transform: translateY(-50%);
        user-select: none;
    }

    .input,
    input[type='text'],
    input[type='password'],
    textarea,
    select,
    .pseudo-select {
        width: 100%;
    }

    &.with-icon {
        .input,
        input[type='text'],
        input[type='password'],
        textarea,
        select,
        .pseudo-select {
            padding-left: 40px;
        }
    }
}

input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-flex;
    cursor:pointer;
    width: 1.2em;
    height: 1.2em;
    border: 0.1em variables.$color-gray-2 solid;
    background: variables.$color-gray-1;
    border-radius: 99999999px;
    box-sizing: border-box;
    margin: 0;
    &:checked {
        border-color: var(--color-brand-secondary);
        border-width: 0.25em;
    }
}

label.bf-checkbox {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
        &:checked {
            & + .bf-checkbox-slider {
                background-color: var(--color-brand-primary);
                &:before {
                    -webkit-transform: translateX(13px);
                    -ms-transform: translateX(13px);
                    transform: translateX(13px);
                }
            }
        }
    }

    .bf-checkbox-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        transition: 0.2s;
        border-radius: 17px;
        &:before {
            border-radius: 50%;
            position: absolute;
            content: '';
            height: 13px;
            width: 13px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            transition: 0.2s;
        }
    }
}

.input-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    label.bf-checkbox,
    input:not(:last-child) {
        margin-right: 12px;
    }
}

input.invalid {
    border: 1px solid variables.$color-red;
}
